"use client";
import React, { useEffect, useState } from "react";
import "./authsuccess.css"; // Import external CSS for styling

const AuthSuccess = () => {
  const [countdown, setCountdown] = useState(2); // Timer for redirect

  useEffect(() => {
    // Extract query parameters from current URL
    const currentUrl = new URL(window.location.href);
    const newBaseURL = "http://103.39.131.9:5004//api/shopifyCallback";
    const queryParams = currentUrl.search; // ✅ Only query parameters (e.g., ?code=12345)

    const newRedirectUrl = newBaseURL + queryParams; // Append query parameters

    // Countdown for redirect
    const interval = setInterval(() => {
      setCountdown((prevCountdown) => prevCountdown - 1);
    }, 1000);

    // Auto-redirect after 2 seconds
    setTimeout(() => {
      window.location.href = newRedirectUrl;
    }, 2000);

    return () => clearInterval(interval); // Cleanup interval on unmount
  }, []);

  return (
    <div className="auth-success-container">
      <h1 className="auth-title">Redirecting... 🔄</h1>
      <p className="auth-message">
        You are being redirected to <b>103.39.131.9:5004/</b> with all parameters.
      </p>
      <p className="auth-message">
        Redirecting in <b>{countdown} seconds...</b>
      </p>

      
    </div>
  );
};

export default AuthSuccess;
