import React, { useState, useRef, useEffect } from 'react';
import './CTOProfile.css';

// Image imports
import ctoImage from '../images/1Digit-Technology.jpg';
import experienceImg from '../images/cybersecurity.png';
import skillsImg from '../images/1Digit-Technology.jpg';
import visionImg from '../images/1Digit-Technology.jpg';

const Hitesh = () => {
    const [faqOpen, setFaqOpen] = useState(null);
    const faqRefs = useRef([]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (faqOpen !== null && faqRefs.current.every(ref => !ref.contains(event.target))) {
                setFaqOpen(null);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, [faqOpen]);

    const toggleFaq = (index) => {
        setFaqOpen(faqOpen === index ? null : index);
    };

    const faqs = [
        { question: "What technologies does 1 Digit Technology specialize in?", answer: "We specialize in Web Development, AI solutions, SaaS applications, and custom software development." },
        { question: "How does Hitesh approach software innovation?", answer: "By focusing on scalable, high-performance, and cutting-edge technologies that solve real-world problems efficiently." },
        { question: "Can I connect with Hitesh for consulting?", answer: "Yes! You can reach out via email or LinkedIn for business collaborations or consulting inquiries." },
    ];

    return (
        <div className="cto-profile-page">
            {/* Hero Section */}
            <header className="header">
                <div className="header-content">
                    <h1>Meet Our CTO</h1>
                    <p>Driving innovation and technology at 1 Digit Technology</p>
                </div>
            </header>

            {/* About CTO Section */}
            <section className="about-cto">
                <div className="cto-image">
                    <img src={ctoImage} alt="Hitesh Sarkheliya - CTO" />
                </div>
                <div className="cto-bio">
                    <h2>Hitesh Sarkheliya</h2>
                    <h3>Chief Technology Officer, 1 Digit Technology</h3>
                    <p>
                        Hitesh Sarkheliya is a visionary CTO with over a decade of experience in leading 
                        software development, AI solutions, and SaaS innovations. Passionate about 
                        cutting-edge technology, he drives strategic growth and leads a talented 
                        development team at 1 Digit Technology.
                    </p>
                </div>
            </section>

            {/* Experience & Achievements */}
            <section className="experience-section">
                <h2>Experience & Achievements</h2>
                <div className="experience-container">
                    <div className="experience-item">
                        <img src={experienceImg} alt="Experience" />
                        <h3>10+ Years in Technology</h3>
                        <p>Expertise in software architecture, AI, and web solutions.</p>
                    </div>
                    <div className="experience-item">
                        <img src={visionImg} alt="Innovation" />
                        <h3>Innovation Leadership</h3>
                        <p>Led multiple successful tech products & enterprise solutions.</p>
                    </div>
                </div>
            </section>

            {/* Skills Section */}
            <section className="skills-section">
                <h2>Technical Skills</h2>
                <div className="skills-container">
                    <div className="skills-item">
                        <img src={skillsImg} alt="Tech Stack" />
                        <h3>Programming Languages</h3>
                        <p>JavaScript (React, Node.js), Python, TypeScript</p>
                    </div>
                    <div className="skills-item">
                        <img src={skillsImg} alt="Architecture" />
                        <h3>Cloud & DevOps</h3>
                        <p>AWS, Docker, Kubernetes, CI/CD</p>
                    </div>
                    <div className="skills-item">
                        <img src={skillsImg} alt="AI" />
                        <h3>AI & Machine Learning</h3>
                        <p>Natural Language Processing, AI Model Fine-Tuning</p>
                    </div>
                </div>
            </section>

            {/* Vision & Mission */}
            <section className="vision-section">
                <h2>Vision & Mission</h2>
                <p>
                    "Our mission at 1 Digit Technology is to harness the power of AI and advanced 
                    development frameworks to build scalable, intelligent solutions that drive 
                    businesses forward."
                </p>
            </section>

            {/* Contact & Social Links */}
            <section className="contact-section">
                <h2>Connect with Hitesh</h2>
                <div className="contact-info">
                    <p>Email: <a href="mailto:hitesh@1digittech.com">hitesh@1digittech.com</a></p>
                    <p>LinkedIn: <a href="https://linkedin.com/in/hiteshsarkheliya" target="_blank" rel="noopener noreferrer">linkedin.com/in/hiteshsarkheliya</a></p>
                    <p>Website: <a href="https://1digittech.com" target="_blank" rel="noopener noreferrer">1digittech.com</a></p>
                </div>
            </section>

            {/* FAQ Section */}
            <section className="faq-section">
                <h2>Frequently Asked Questions</h2>
                <div className="faqs">
                    {faqs.map((faq, index) => (
                        <div key={index} className="faq-item" ref={(el) => (faqRefs.current[index] = el)}>
                            <button className="faq-question" onClick={() => toggleFaq(index)}>
                                <span>{faq.question}</span>
                                <span className={`faq-arrow ${faqOpen === index ? 'open' : ''}`}></span>
                            </button>
                            <div className={`faq-answer ${faqOpen === index ? 'show' : ''}`}>
                                <p>{faq.answer}</p>
                            </div>
                        </div>
                    ))}
                </div>
            </section>
        </div>
    );
};

export default Hitesh;
